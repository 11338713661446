import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'

import { PageLoader } from './components/page-loader'

// eslint-disable-next-line react/prop-types
export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    // TODO: Update to use window.location.host
    // logo_url: 'https://tactdatamanagement.app/api/logo.png',
    // data: {
    //   name: 'Take ACTion Data Management Testings',
    // },
    loginOptions: {
      appState: {
        targetUrl: `${window.location.origin}/${window.location.hash}`,
        hash: window.location.hash,
        href: window.location.href,
        location: window.location,
      },
    },
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  })

  return <Component />
}
