import { Auth0Provider } from '@auth0/auth0-react'

import React from 'react'

// eslint-disable-next-line react/prop-types
export const Auth0ProviderWithNavigate = ({ children }) => {
  const domain = window.tact.domain
  const clientId = window.tact.clientId
  const audience = window.tact.audience

  const onRedirectCallback = async (appState) => {
    window.location.hash = window.location.hash // eslint-disable-line no-self-assign

    if (window.debugMode) {
      console.log('onRedirectCallback - appState', appState)
      console.log('onRedirectCallback - window.location.href', window.location.href)
    }

    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl ? appState.targetUrl : window.location.href,
    )
  }

  if (!(domain && clientId)) {
    return null
  }

  if (window.debugMode) {
    console.log('window.location.href 1: ', window.location.href)
    console.log('window.location.origin 1: ', window.location.origin)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
