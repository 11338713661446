import React from 'react'

export const PageLoader = () => {
  // const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg'

  // return <div className="loader" />

  return (
    <div className="spinner-border text-primary m-5" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}
