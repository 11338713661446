import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate'
import axios from 'axios'

const container = document.getElementById('root')
const root = createRoot(container)

const apiLocation = window.location.origin.replace('3000', '3001')
window.apiLocation = apiLocation

const tact = await axios(`${apiLocation}/api/env.js`)
window.tact = tact.data

const languageCurrentRaw = localStorage.getItem('languageCurrent') || 'en_CA'

if (languageCurrentRaw !== window.currentLanguage) {
  delete window.language

  window.currentLanguage = languageCurrentRaw
}

const languageRaw = localStorage.getItem('language')
if (languageRaw) {
  window.language = JSON.parse(languageRaw)
} else {
  const languageRaw = await axios(`${apiLocation}/api/language/${window.currentLanguage}`)
  localStorage.setItem('language', JSON.stringify(languageRaw.data))
  localStorage.setItem('languageCurrentRaw', JSON.stringify(window.currentLanguage))

  window.language = languageRaw.data
}

if (window.location.hostname === 'localhost') {
  window.debugMode = true
} else {
  document.addEventListener(
    'contextmenu',
    function (e) {
      e.preventDefault()
    },
    false,
  )
}

// console.log('index: ', `${window.location.origin}/${window.location.hash}${window.location.search}`)
// window.href = `${window.location.origin}/${window.location.hash}${window.location.search}`

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
